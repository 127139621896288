import React from 'react';
import googleContinue from './web_light_rd_ctn.svg'

function Sign() {

    const baseUrl = new URL("https://accounts.google.com/o/oauth2/auth")
    baseUrl.searchParams.append("client_id", process.env.REACT_APP_GOOGLE_CLIENT_ID!)
    baseUrl.searchParams.append("redirect_uri", process.env.REACT_APP_REDIRECT_URI!)
    baseUrl.searchParams.append("response_type", "code")
    baseUrl.searchParams.append("scope", "https://www.googleapis.com/auth/userinfo.email")
    baseUrl.searchParams.append("access_type", "offline")

    function signIn() {
        const authcode = ''
        fetch('http://localhost:8080/api/v1/auth', {
            method: 'POST', headers: {
                'content-type': 'application/json',
            }, body: JSON.stringify({authcode: authcode})
        })
            .then(res => {
                // save jwt in local storage
                console.log(res.json())
            })
            .catch(err => console.log(err))
    }

    function clickContinueButton() {

        console.log("FFFFFFFFFF")
    }

    return (
        <div className="App">
            <a href={baseUrl.toString()}>
                <img
                    src={googleContinue}
                    className="google-continue"
                    onClick={clickContinueButton}
                    alt="google-continue"
                />
            </a>
        </div>);
}

export default Sign;
