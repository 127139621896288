import React, {useEffect, useState} from "react";
import {Mode} from "./mode";
import {Socket} from "socket.io-client"
import {AdminDataResponse, AdminStateResponse, State} from "./state";
import soc from "./soc";
import {base64ToUint8Array} from "./utils";

function Admin({mode}: { mode: Mode }) {
    const [socket, setSocket] = useState<Socket | null>(null)
    const [studentState, setStudentState] = useState<State>(State.Offline)
    const [teacherState, setTeacherState] = useState<State>(State.Offline)
    const [page, setPage] = useState<number>(0)
    const [imgPath, setImgPath] = useState<string>("")
    const [inputValue, setInputValue] = useState<string>("")
    const [messageStatus, setMessageStatus] = useState<boolean | null>(null)
    const [dataList, setDataList] = useState<AdminDataResponse[]>([])
    const [audioContext, setAudioContext] = useState<AudioContext | null>(null)



    function playAudio(ac: AudioContext, audio: string) {
        const uint8Array = base64ToUint8Array(audio)
        console.log(uint8Array.length)
        const int16Array = new Int16Array(uint8Array.length/2)
        for (let i = 0; i < int16Array.length; i++) {
            int16Array[i] = (uint8Array[2 * i + 1] << 8) | (uint8Array[2 * i])
        }
        console.log(int16Array.length)

        let float32Array = new Float32Array(int16Array.length)
        const maxInt16 = 1 << 15;
        for (let i = 0; i < int16Array.byteLength; i++) {
            float32Array[i] = int16Array[i] / maxInt16
        }

        const audioContext = new AudioContext({sampleRate:16000})
        const audioBuffer = audioContext.createBuffer(1, int16Array.length, 16000)
        audioBuffer.getChannelData(0).set(float32Array)

        const source = audioContext.createBufferSource()
        // source.channelCount = 1
        source.buffer = audioBuffer
        source.connect(audioContext.destination)
        source.start()
    }


    function connect() {
        const audioContext = new AudioContext({sampleRate:16000})
        setAudioContext(audioContext)
        const s = soc(mode)
        setSocket(s)
        s.on("syncAdminState", (response: AdminStateResponse) => {
            setStudentState(response.student)
            setTeacherState(response.teacher)
            setPage(response.page)
            setImgPath((prevState) => (`https://classroom-materials-web.pages.dev/img/slides/${response.page}.jpg`))
        })
        s.on("getData", (response: AdminDataResponse) => {
            let newData = 'some audio data'
            if (response.type === "sttResult") {
                newData = response.data
            } else {
                // play audio data here
                playAudio(audioContext, response.data)
            }

            setDataList((prev) => [...prev, {"type": response.type, "data": newData}])
        })
    }

    function sendMessage(namespace: string) {
        if (socket == null) {
            // send dialog - no message or socket is null
        } else {
            socket.emit("sendMessage", {
                    "namespace": namespace,
                    "message": inputValue
                },
                (response: { success: boolean }) => {
                    if (response.success) {
                        setMessageStatus(true)
                    } else {
                        setMessageStatus(false)
                    }
                })
        }

    }

    function sendToTeacher() {
        sendMessage("/teacher")
    }

    function sendToStudent() {
        sendMessage("/student")
    }

    function handleInputChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        setInputValue(event.target.value)
    }

    function onPageChange(event: React.ChangeEvent<HTMLInputElement>) {
        setPage(parseInt(event.target.value))
    }

    function onSetPage() {
        if (socket != null) {
            socket.emit("setPage", {page: page})
        }
    }

    return <div>
        <h1>ADMIN PAGE {page}</h1>
        <img src={imgPath} width={500} height={500} style={{objectFit: "contain"}}/>
        <button onClick={connect}>click to connect</button>
        <input type="number" value={page} onChange={onPageChange}></input>
        <button onClick={onSetPage}>setPageEvent</button>
        <h2>student: {studentState}</h2>
        <h2>teacher: {teacherState}</h2>
        <h2>sent message status: {
            messageStatus == null ?
                "Message ..." :
                <>
                    {
                        messageStatus ?
                            "Message Success" :
                            "Message Failed"
                    }
                </>
        }</h2>
        <br/>

        <h1>INPUT MESSAGE HERE</h1>
        <textarea
            value={inputValue}
            onChange={handleInputChange}
        />
        <button onClick={sendToTeacher}>TEACHER</button>
        <button onClick={sendToStudent}>STUDENT</button>

        <ul>
            {
                dataList.map((item, key) => {
                    return <li key={key}>{item.type}: {item.data}</li>
                })
            }
        </ul>


    </div>
}

export default Admin;