import React, {useEffect, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";

function Callback() {

    const hasRun = useRef(false)
    const code = new URLSearchParams(useLocation().search).get("code");
    const navigate = useNavigate()

    useEffect(() => {
        if (!hasRun.current) {
            hasRun.current = true
            if (code === undefined) {
                console.log("code is null")
            }
            // login
            fetch("http://localhost:8080/api/v1/auth",
                {
                    method: "POST",
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify({authCode: code})
                })
                .then(async res => {
                    const j = await res.json()
                    console.log(j)
                    // save jwt to local storage
                    localStorage.setItem("jwt", j.data.token)
                    // redirect to chat
                    navigate("/conversation")
                })
                .catch(err => console.log(err))

        }
    })

    return (
        <div></div>
    )
}

export default Callback;