// export function base64StringToUint8Array(base64: string): Uint8Array {
//     const binary = window.atob(base64);
//     const binary = Buffer.from(base64, 'base64');
//     return new Uint8Array(binary)
// }
export function base64ToUint8Array(base64: string): Uint8Array {
    console.log(base64.length)
    // const padding = '='.repeat((4 - (base64.length % 4)) % 4);
    // const base64Padded = (base64 + padding)
    //   .replace(/\-/g, '+')
    //   .replace(/_/g, '/');

    // const rawData = atob(base64Padded);
    const rawData = atob(base64)
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}