import React, {ChangeEvent, useEffect, useState} from "react";
import {io, Socket} from "socket.io-client"


function Conversation() {

    enum Status {
        Offline = "Offline",
        Listening = "Listening",
        Waiting = "Waiting",
        Speaking = "Speaking"
    }

    const [status, setStatus] = useState<Status>(Status.Offline);
    const [soc, setSoc] = useState<Socket | null>(null);
    const [buf, setBuf] = useState<Float32Array[]>([]);
    // const [audioContext, setAudioContext] = useState<AudioContext | null>(null);


    function connectToWS(endpoint: string) {
        console.log(`http://localhost:5959/${endpoint}`)
        const socket = io(`http://localhost:5959/${endpoint}`);
        socket.on("receiveAudioChunks", (received_data) => {
            // change status to speaking
            setStatus(Status.Speaking)
            // play audio
            const audio_data = new Int16Array(received_data)
            // change to Float32Array for audio play
            const normalized = new Float32Array(audio_data.length/2)
            audio_data.forEach((value, index) => {
                normalized[index] = value / 32768.0
            })
            const temp = buf
            temp.push(normalized)
            setBuf(temp)
            console.log(`length: ${audio_data.length} ${normalized.length} ${temp.length} ${buf.length}`);
            // change status to listening again
            // startPlaying()
        })
        socket.on("connect_error", (err) => {
            console.log("error at WS connection")
            console.log(err.message)
        })
        socket.on("connect", () => {
            console.log("Connected" + socket.id);
        })
        socket.on("disconnect", () => {
            console.log("Disconnected" + socket.id);
        })
        setSoc(socket)
    }

    function makeConnection() {
        if (status !== Status.Offline) {
            return
        }
        fetch("http://localhost:8080/api/v1/conversation/connect",
            {
                method: "POST",
                headers: {
                    'content-type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('jwt'),
                },
                body: JSON.stringify({})
            })
            .then(async res => {
                return await res.json()
            })
            .then(jsonResponse => {
                connectToWS(jsonResponse.data.endpoint)
                setStatus(Status.Listening)
            })
        ;
    }

    const [file, setFile] = useState<File | null>(null);

    function handleFileChange(evt: ChangeEvent<HTMLInputElement>) {
        if (evt.target.files) {
            setFile(evt.target.files[0]);
        }
    }

    function playAudio() {
        console.log("Starting playing");
        const audioContext = new (window.AudioContext)();
        console.log(buf.length)
        const audioBuffer = audioContext.createBuffer(1, buf.length, 16000)
        const channelData = audioBuffer.getChannelData(0)
        buf.forEach((chunk) => {
            chunk.forEach((value, index) => {
                channelData[index] = value
            })
        })

        const source = audioContext.createBufferSource();
        source.buffer = audioBuffer
        source.connect(audioContext.destination);
        source.start()
    }

    function sendAudio() {
        if (!file || !soc) {
            console.log("No file selected || soc is not available");
            return
        }

        const reader = new FileReader();
        reader.readAsArrayBuffer(file)
        reader.onload = () => {
            const arrayBuffer = reader.result as ArrayBuffer;
            soc.emit("audio", arrayBuffer);
            setStatus(Status.Waiting)
        }
    }

    useEffect(() => {
        console.log("updated")

    }, [status])



    return (
        <div>
            <h1>CONVERSATION</h1>
            <h2>{status}</h2>
            <input type="file" accept="audio/mp3" onChange={handleFileChange}/>
            <br/>
            {
                status === Status.Offline ?
                    <button onClick={makeConnection}>START CONVERSATION</button> :
                    <></>
            }
            <br/>
            <button onClick={sendAudio}>SEND AUDIO</button>
            <button onClick={playAudio}>play audio</button>
            <br/>
        </div>
    )
}

export default Conversation;
