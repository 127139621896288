import {io, Socket} from "socket.io-client";

function createSocket(endpoint: string): Socket {
    const socket = io(`https://server.ktalker.kr/${endpoint}`)
    // const socket = io(`http://localhost:5959/${endpoint}`);
    socket.on("connect", () => {
        console.log(`Socket connected to ${endpoint}\nsid: ${socket.id}`);
    })
    socket.on("disconnect", () => {
        console.log(`Socket disconnected from ${endpoint}\nsid: ${socket.id}`);
    })
    socket.on("connect_error", (err) => {
        console.log(`Socket connection error from ${endpoint}\nsid: ${socket.id}`);
        console.log(err)
    })
    socket.on("getMessage", (message) => {
       console.log("message", message)
    })
    return socket
}

export default createSocket;