import React, {ChangeEvent, useEffect, useState} from "react";
import {Socket} from "socket.io-client";
import soc from "./soc";
import {State, StateResponse, AudioResponse} from "./state";
import {Mode} from "./mode"

function User({mode}: { mode: Mode }) {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [state, setState] = useState<State>(State.Offline);
    const [otherState, setOtherState] = useState<State>(State.Offline);
    const [page, setPage] = useState<number>(0);
    const [buffer, setBuffer] = useState<string[]>([])


    function connect() {
        const s = soc(mode)
        setSocket(s)
        s.on("syncState", (response: StateResponse) => {
            console.log("syncState", response)
            setState(response.state)
            setOtherState(response.other)
            setPage(response.page)
        })
        s.on("getAudio", (response: AudioResponse) => {
            console.log("getAudio", response)
            console.log(buffer)
            setBuffer((prev) => [...prev, response.data])
        })
    }

    function speechDetected() {
        if (socket !== null) {
            socket.emit("speechDetected", {"data": "sampleAudioData"})
        }
    }

    function speechFinished() {
        if (socket !== null) {
            socket.emit("speechFinished")
        }
    }

    useEffect(() => {
        console.log(`state updated: ${state} ${otherState} ${buffer.length}`);
    }, [state, otherState, buffer]);
    const [file, setFile] = useState<File | null>(null);
    function handleFileChange(evt: ChangeEvent<HTMLInputElement>) {
        if (evt.target.files) {
            setFile(evt.target.files[0]);
        }
    }
    function sendAudio() {
        if (socket !== null) {
            let audioData = ''
            if (file !== null) {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file)
                reader.onload = () => {
                    const arrayBuffer = reader.result as ArrayBuffer;
                    const uint8Array = new Uint8Array(arrayBuffer);
                    let binaryString = ''
                    for (let i = 0; i < uint8Array.length; i++) {
                        binaryString += String.fromCharCode(uint8Array[i]);
                    }
                    const base64string = btoa(binaryString);
                    audioData = base64string
                    socket.emit("sendAudio", {
                        "data": audioData,
                        "target": mode.valueOf()
                    })
                }
            } else {
                socket.emit("sendAudio")
            }
        }
    }

    return (
        <div>
            <h1>{mode} PAGE {page}</h1>
            <input type="file" accept="audio/mp3" onChange={handleFileChange}/> :
            <button onClick={sendAudio}>sendAudio</button>
            <h2>{state}</h2>
            <p>Other's state: {otherState}</p>
            <br/>

            <button onClick={connect}>CLICK TO CONNECT</button>
            <button onClick={speechDetected}>speechDetected</button>
            <button onClick={speechFinished}>speechFinished</button>
            <ul>
                {
                    buffer.map((elem, index) => {
                        return <li key={index}>{elem}</li>
                    })
                }
            </ul>
        </div>
    )
}

export default User;