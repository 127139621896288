export enum State {
    Offline = "Offline",
    PreSpeaking = "PreSpeaking",
    Speaking = "Speaking",
    PreListening = "PreListening",
    Listening = "Listening",
}

export interface StateResponse {
    state: State;
    other: State;
    page: number;
}

export interface AdminStateResponse {
    student: State;
    teacher: State;
    page: number;
}

export interface AudioResponse {
    data: string;
}

export interface AdminDataResponse {
    type: string;
    data: string;
}