import React from 'react';
import {Routes, Route} from "react-router-dom";
import './App.css';
import Sign from './sign/sign'
import Callback from './sign/callback'
import NotFound from './notfound/notfound'
import Conversation from './conversation/conversation'
import User from "./conversation/user";
import Admin from "./conversation/admin"
import {Mode} from "./conversation/mode"

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Admin mode={Mode.admin}/>}/>
                <Route path="/sign" element={<Sign />}/>
                <Route path="/sign/callback" element={<Callback />} />
                <Route path="/conversation" element={<Conversation />}/>
                <Route path="/student" element={<User mode={Mode.student}/>}/>
                <Route path="/teacher" element={<User mode={Mode.teacher}/>}/>
                <Route path="/llm" element={<User mode={Mode.llm}/>}/>
                <Route path="/admin" element={<Admin mode={Mode.admin}/>}/>

                <Route path="*" element={<NotFound />}/>
            </Routes>
        </div>);
}

export default App;
